/* THIS FILE HAS BEEN COPIED FROM PORTAL DO NOT EDIT IT DIRECTLY */
import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface Props {
  top: React.ReactNode;
  children: React.ReactNode;
}

function AppLayout({ top, children }: Props) {
  return (
    <Box position="relative" display="flex" flexDir="column" h="100vh" overflow="hidden" bg="white">
      {top}
      {children}
    </Box>
  );
}

const ScrollableContent = React.forwardRef(function ScrollableContent(
  props: BoxProps,
  ref: React.LegacyRef<HTMLDivElement>,
) {
  return <Box ref={ref} flex="1" overflowY="auto" {...props} />;
});

AppLayout.FixedContent = Box;
AppLayout.ScrollableContent = ScrollableContent;

export default AppLayout;
