import { chakra } from '@chakra-ui/react';
import { countries, getCountryCode, getCountryData, getCountryDataList } from 'countries-list';
import { isNil } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import Select, { ActionMeta } from 'react-select';

export interface CountryType {
  value: string;
  label: string | undefined;
}

const emptyCountry: CountryType = {
  value: '',
  label: '',
};

export function getCountryName(countryCode: string | undefined | null): string {
  const countryData = getCountryDataList().find(({ iso3 }) => iso3 === countryCode);
  return countryData?.name ?? '';
}

function getAlpha3CountryCode(name: string): string | undefined {
  const countryCode = getCountryCode(name);
  if (!isNil(countryCode) && countryCode != undefined && countryCode !== false) {
    return getCountryData(countryCode).iso3;
  } else {
    return undefined;
  }
}

const siteOptions: CountryType[] = Object.values(countries).map((countryCode) => ({
  value: countryCode.name,
  label: getAlpha3CountryCode(countryCode.name),
}));

interface props {
  onChange: (value: CountryType) => void;
  tabIndex: number;
  country?: string;
}

export function CountrySelect({ onChange, tabIndex, country }: props) {
  const defaultCountry: CountryType = {
    value: getCountryName(country) ?? '',
    label: country ?? '',
  };
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const currentCountry = useMemo(() => {
    return siteOptions.find((option) => option.value === selectedCountry.value);
  }, [selectedCountry]);

  const formatOptionLabel = useCallback((data: CountryType) => {
    return <chakra.span>{data.value}</chakra.span>;
  }, []);

  const handleChange = async (option: CountryType | null, meta: ActionMeta<CountryType>) => {
    if (meta.action === 'clear') {
      setSelectedCountry(emptyCountry);
      onChange(emptyCountry);
    } else {
      setSelectedCountry(option ?? emptyCountry);
      onChange(option ?? emptyCountry);
    }
  };

  return (
    <Select
      value={currentCountry}
      options={siteOptions}
      tabIndex={tabIndex}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      placeholder={'Select a country'}
      isLoading={false}
      isDisabled={false}
      isMulti={false}
      escapeClearsValue={true}
      isClearable={true}
      isSearchable={true}
      menuPlacement="auto"
    />
  );
}
