import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Center, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <Center flex={1}>
      <Stack>
        <Text>Oops, looks like we brought you nowhere :(</Text>
        <Button as={Link} to="/" rightIcon={<ChevronRightIcon />}>
          Take me back to the beginning
        </Button>
      </Stack>
    </Center>
  );
}

export default NotFoundPage;
