/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { ProStoreConnection_OrganizationFragmentDoc } from './ProStoreConnection.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationRowFragment = { __typename?: 'Organization', id: string, name: string, handle: string, createdAt: any, enableMfa?: boolean | null, displayCount: number, countryCode?: string | null, owner?: { __typename?: 'OwnerUser', id: string, email: string, lastLogin?: any | null } | null, proStore?: { __typename?: 'ProStoreConnection', id: string, createdAt: any, developerAccess: boolean } | null };

export type SendInvitationMutationVariables = Types.Exact<{
  input: Types.SendInvitationInput;
}>;


export type SendInvitationMutation = { __typename?: 'Mutation', sendInvitation: boolean };

export const OrganizationRowFragmentDoc = gql`
    fragment OrganizationRow on Organization {
  id
  name
  handle
  createdAt
  enableMfa
  displayCount
  countryCode
  owner {
    id
    email
    lastLogin
  }
  ...ProStoreConnection_organization
}
    ${ProStoreConnection_OrganizationFragmentDoc}`;
export const SendInvitationDocument = gql`
    mutation SendInvitation($input: SendInvitationInput!) {
  sendInvitation(input: $input)
}
    `;
export type SendInvitationMutationFn = ApolloReactCommon.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, options);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = ApolloReactCommon.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendInvitationMutation, SendInvitationMutationVariables>;