/* THIS FILE HAS BEEN COPIED FROM PORTAL DO NOT EDIT IT DIRECTLY */
import { Box, HStack, Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

export function Navigation({ children }: Props) {
  return (
    <HStack spacing="16" height="100%">
      {children}
    </HStack>
  );
}

export function NavigationLink({ to, children }: { to: string; children: React.ReactNode }) {
  const match = useMatch(to);

  return (
    <ChakraLink
      as={Link}
      to={to}
      height="100%"
      color={match ? 'white' : 'gray.300'}
      _hover={{
        textTransform: 'none',
      }}
      _focus={{
        boxShadow: 'none',
      }}
    >
      <Box
        role="group"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        userSelect="none"
        fontWeight="medium"
      >
        <Box flex="1" display="flex" justifyContent="center" alignItems="center" px="4">
          {children}
        </Box>
        <Box
          width="100%"
          height="3px"
          backgroundColor={match ? 'blue.300' : 'inherit'}
          _groupHover={{
            backgroundColor: 'blue.400',
          }}
        ></Box>
      </Box>
    </ChakraLink>
  );
}
