import jwtDecode from 'jwt-decode';
import { isEqual, isNil } from 'lodash';
import { useContext, useState } from 'react';
import { AuthenticationContext } from './AuthenticationProvider';

export interface User {
  name: string;
  'https://keat.io/audiences'?: string[];
  permissions?: string[];
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);
  const [permissions, setPermissions] = useState<string[]>([]);

  if (isNil(context)) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider');
  }

  const auth = useContext(context);

  if (auth.isAuthenticated && permissions.length === 0) {
    auth.getAccessTokenSilently().then((token) => {
      const decodedToken = jwtDecode<{ permissions?: string[] }>(token);
      const tokenPermissions = decodedToken.permissions ?? [];

      if (!isEqual(tokenPermissions, permissions)) {
        setPermissions(tokenPermissions);
      }
    });
  }

  return {
    ...auth,
    user: {
      ...(auth.user as User),
      permissions: [],
    },
  };
}
