import { Button, chakra, Checkbox } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Column, Row, useSortBy, useTable } from 'react-table';
import { OrganizationRowFragment } from '~components/organizations/__generated__/OrganizationsTable.graphql';
import { ProStoreConnection } from '~components/organizations/ProStoreConnection';
import { ProStoreDeveloperAccess } from '~components/organizations/ProStoreDeveloperAccess';
import { FeatureFlag } from '~utils/features';
import { getCountryName } from './CountrySelect';

export enum Columns {
  Name = 'name',
  Handle = 'handle',
  DisplayCount = 'displayCount',
  OnboardedAdmin = 'onboardedAdmin',
  Country = 'Country',
  DateCreated = 'dateCreated',
  LastLogin = 'lastLogin',
  Edit = 'edit',
  Delete = 'delete',
  Mfa = 'Mfa',
  LinkProStore = 'linkProStore',
  DeveloperAccess = 'DeveloperAccess',
}

type UseOrganizationsTableProps = {
  data: OrganizationRowFragment[];
  onOrganizationEdit: (id: string) => void;
  onOrganizationDelete: (id: string) => void;
  mfaChangeOnOrganization: (id: string, mfaEnabled: boolean) => void;
};

export const useOrganizationsTable = ({
  data,
  onOrganizationEdit,
  onOrganizationDelete,
  mfaChangeOnOrganization,
}: UseOrganizationsTableProps) => {
  const columns = useMemo<Array<Column<OrganizationRowFragment>>>(() => {
    const LinkProStoreCell = ({ row }: { row: Row<OrganizationRowFragment> }) => (
      <FeatureFlag feature="prostore">
        <ProStoreConnection organization={row.original} />
      </FeatureFlag>
    );

    const ProStoreDeveloperAccessCell = ({ row }: { row: Row<OrganizationRowFragment> }) => (
      <FeatureFlag feature="prostore">
        <ProStoreDeveloperAccess organization={row.original} />
      </FeatureFlag>
    );

    const EditCell = ({ row }: { row: Row<OrganizationRowFragment> }) => (
      <Button variant="outline" onClick={() => onOrganizationEdit(row.original.id)}>
        Edit
      </Button>
    );

    const MfaCell = ({ row }: { row: Row<OrganizationRowFragment> }) => (
      <Checkbox
        isChecked={row.original.enableMfa ?? false}
        onChange={(event) => mfaChangeOnOrganization(row.original.id, event.target.checked)}
      ></Checkbox>
    );

    const DeleteCell = ({ row }: { row: Row<OrganizationRowFragment> }) => (
      <Button variant="outline" onClick={() => onOrganizationDelete(row.original.id)}>
        Delete
      </Button>
    );

    const DateCell = ({ value }: { value: string }) => (
      <>{value ? format(new Date(value), 'MMM d, y') : 'Never'}</>
    );

    const NoWrapCell = ({ value }: { value: string }) => (
      <chakra.span whiteSpace="nowrap">{value}</chakra.span>
    );

    return [
      {
        id: Columns.Name,
        Header: 'Name',
        accessor: (o) => o.name,
        Cell: NoWrapCell,
      },
      {
        id: Columns.Handle,
        Header: 'Handle',
        accessor: (o) => o.handle,
        Cell: NoWrapCell,
      },
      {
        id: Columns.DisplayCount,
        Header: 'Display count',
        accessor: (o) => o.displayCount,
      },
      {
        id: Columns.Country,
        Header: 'Country',
        accessor: (o) => getCountryName(o.countryCode) ?? 'UNKNOWN',
      },
      {
        id: Columns.OnboardedAdmin,
        Header: 'Onboarded Admin',
        accessor: (o) => o.owner?.email,
      },
      {
        id: Columns.DateCreated,
        Header: 'Date created',
        accessor: (o) => o.createdAt,
        Cell: DateCell,
      },
      {
        id: Columns.Mfa,
        Header: 'Mfa',
        Cell: MfaCell,
      },
      {
        id: Columns.LastLogin,
        Header: 'Last login',
        accessor: (o) => o.owner?.lastLogin,
        Cell: DateCell,
      },
      {
        id: Columns.Edit,
        Header: '',
        Cell: EditCell,
      },
      {
        id: Columns.Delete,
        Header: '',
        Cell: DeleteCell,
      },
      {
        id: Columns.LinkProStore,
        Header: '',
        Cell: LinkProStoreCell,
      },
      {
        id: Columns.DeveloperAccess,
        Header: 'ProStore Developer access',
        Cell: ProStoreDeveloperAccessCell,
      },
    ];
  }, [onOrganizationEdit, onOrganizationDelete, mfaChangeOnOrganization]);

  return useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: Columns.DateCreated, desc: true }],
      },
    },
    useSortBy,
  );
};
