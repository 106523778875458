import { gql } from '@apollo/client';
import { Box, Center, Heading, Spinner, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CreateAndroidFirmwareReleaseModal from '~components/firmware/CreateAndroidFirmwareReleaseModal';
import FirmwareTable from '~components/firmware/FirmwareTable';
import AppLayout from '~components/ui/AppLayout';
import Breadcrumb from '~components/ui/Breadcrumb';
import Oops from '~components/ui/Oops';
import { GetAllPlatformsQuery, useGetAllPlatformsQuery } from './__generated__/Firmware.graphql';

type SelectedPlatform = GetAllPlatformsQuery['platforms'][0];

function FirmwarePage() {
  const [selectedPlatform, setSelectedPlatform] = useState<SelectedPlatform | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading, error, data } = useGetAllPlatformsQuery();

  function handleNewFirmware(platform: SelectedPlatform) {
    setSelectedPlatform(platform);
    onOpen();
  }

  return (
    <>
      <Helmet>
        <title>Firmware</title>
      </Helmet>

      <>
        <AppLayout.FixedContent>
          <Box px="16" py="4" bg="gray.200">
            <Breadcrumb>
              <Breadcrumb.Item>TP Vision</Breadcrumb.Item>
              <Breadcrumb.ItemLink to={`/firmware`}>Firmware</Breadcrumb.ItemLink>
            </Breadcrumb>
          </Box>
        </AppLayout.FixedContent>

        <AppLayout.ScrollableContent ml="8">
          <Heading mt="4">Firmware Management</Heading>
          <Box marginTop="8">
            {loading && (
              <Center flex={1}>
                <Spinner size="lg" />
              </Center>
            )}
            {error && <Oops error={error} />}
            {data && <FirmwareTable platforms={data.platforms} onNewFirmware={handleNewFirmware} />}
          </Box>
        </AppLayout.ScrollableContent>
        {selectedPlatform && (
          <CreateAndroidFirmwareReleaseModal
            platform={selectedPlatform}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </>
    </>
  );
}

FirmwarePage.graphql = {
  queries: {
    GetAllPlatforms: gql`
      query GetAllPlatforms {
        platforms {
          id
          ...FirmwareRow
          ...PlatformFirmwareRelease
        }
      }
    `,
  },
};

export default FirmwarePage;
