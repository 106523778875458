import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';
import { useAuthentication } from '~auth/useAuthentication';
import AdminIcon from '~components/ui/AdminIcon';
import AppBar from '~components/ui/AppBar';
import AppLayout from '~components/ui/AppLayout';
import { Navigation, NavigationLink } from '~components/ui/Navigation';
import UserMenu from '~components/ui/UserMenu';

function IndexPage() {
  const { user, logout } = useAuthentication();

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>

      <AppLayout
        top={
          <AppBar
            left={<AdminIcon name="TP Vision Admin Portal" />}
            right={<UserMenu user={user} onLogout={handleLogout} />}
          >
            <Navigation>
              <NavigationLink to={`/firmware`}>Firmware Management</NavigationLink>
              <NavigationLink to={`/organizations`}>Organizations</NavigationLink>
            </Navigation>
          </AppBar>
        }
      >
        <Outlet />
      </AppLayout>
    </>
  );
}

export default IndexPage;
