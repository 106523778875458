/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ProStoreDeveloperAccess_OrganizationFragment = { __typename?: 'Organization', id: string, proStore?: { __typename?: 'ProStoreConnection', id: string, createdAt: any, developerAccess: boolean } | null };

export type ToggleProstoreDeveloperAccessMutationVariables = Types.Exact<{
  input: Types.OrganizationToggleProstoreDeveloperAccessInput;
}>;


export type ToggleProstoreDeveloperAccessMutation = { __typename?: 'Mutation', organizationToggleProstoreDeveloperAccess: { __typename?: 'Organization', id: string, proStore?: { __typename?: 'ProStoreConnection', id: string, createdAt: any, developerAccess: boolean } | null } };

export const ProStoreDeveloperAccess_OrganizationFragmentDoc = gql`
    fragment ProStoreDeveloperAccess_organization on Organization {
  id
  proStore {
    id
    createdAt
    developerAccess
  }
}
    `;
export const ToggleProstoreDeveloperAccessDocument = gql`
    mutation ToggleProstoreDeveloperAccess($input: OrganizationToggleProstoreDeveloperAccessInput!) {
  organizationToggleProstoreDeveloperAccess(input: $input) {
    id
    ...ProStoreDeveloperAccess_organization
  }
}
    ${ProStoreDeveloperAccess_OrganizationFragmentDoc}`;
export type ToggleProstoreDeveloperAccessMutationFn = ApolloReactCommon.MutationFunction<ToggleProstoreDeveloperAccessMutation, ToggleProstoreDeveloperAccessMutationVariables>;

/**
 * __useToggleProstoreDeveloperAccessMutation__
 *
 * To run a mutation, you first call `useToggleProstoreDeveloperAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleProstoreDeveloperAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleProstoreDeveloperAccessMutation, { data, loading, error }] = useToggleProstoreDeveloperAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleProstoreDeveloperAccessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleProstoreDeveloperAccessMutation, ToggleProstoreDeveloperAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ToggleProstoreDeveloperAccessMutation, ToggleProstoreDeveloperAccessMutationVariables>(ToggleProstoreDeveloperAccessDocument, options);
      }
export type ToggleProstoreDeveloperAccessMutationHookResult = ReturnType<typeof useToggleProstoreDeveloperAccessMutation>;
export type ToggleProstoreDeveloperAccessMutationResult = ApolloReactCommon.MutationResult<ToggleProstoreDeveloperAccessMutation>;
export type ToggleProstoreDeveloperAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleProstoreDeveloperAccessMutation, ToggleProstoreDeveloperAccessMutationVariables>;