import { Auth0Context, Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import AuthenticationProvider from '~auth/AuthenticationProvider';
import ApolloProvider from './graphql/ApolloProvider';
import theme from './theme';

function AppProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: Record<string, string>) => {
      if (!appState?.returnTo) return;
      navigate(appState.returnTo, { replace: true });
    },
    [navigate],
  );
  return (
    <HelmetProvider>
      <AuthenticationProvider
        provider={Auth0Provider}
        providerProps={{
          domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
          authorizationParams: {
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: process.env.REACT_APP_AUTH0_SCOPE,
          },

          onRedirectCallback,
        }}
        context={Auth0Context}
      >
        <ApolloProvider uri={process.env.REACT_APP_API_URI}>
          <ChakraProvider resetCSS theme={theme}>
            {children}
          </ChakraProvider>
        </ApolloProvider>
      </AuthenticationProvider>
    </HelmetProvider>
  );
}

export default AppProvider;
