/* THIS COMPONENT HAS BEEN COPIED FROM PORTAL DO NOT EDIT IT DIRECTLY */
/* SEE AccountMenu.tsx:99 */
import { Avatar, Box, Stack } from '@chakra-ui/react';
import React from 'react';

interface AdminIconProps {
  name: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const AdminIcon = React.forwardRef(function AdminIcon(
  props: AdminIconProps,
  ref: React.LegacyRef<HTMLDivElement>,
) {
  return (
    <Box ref={ref} onClick={props.onClick} role="button" fontWeight="medium">
      <Stack
        role="group"
        direction="row"
        alignItems="center"
        spacing="4"
        color="white"
        _hover={{
          color: 'blue.200',
        }}
      >
        <Avatar
          name={props.name}
          size="sm"
          bg="gray.50"
          color="gray.800"
          _groupHover={{
            backgroundColor: 'blue.200',
          }}
        />
        <Box>{props.name}</Box>
      </Stack>
    </Box>
  );
});

export default AdminIcon;
