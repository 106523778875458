import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  activeStepIndex?: number;
}

function Stepper({ activeStepIndex = -1, children }: Props) {
  const totalSteps = React.Children.toArray(children).length;
  if (activeStepIndex > totalSteps - 1) {
    throw new Error('The active step index must be in range of the provided steps.');
  }

  return (
    <Box>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing="2">
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            throw new Error('Child is not a valid element');
          }

          if (child.type !== Step) {
            throw new Error('Child is not a Stepper.Step component.');
          }

          const isLast = index === totalSteps - 1;
          const stepProps: Omit<StepProps, 'children'> = {
            isActive: index === activeStepIndex,
            isComplete: activeStepIndex > index,
            stepIndex: index,
          };

          const element = React.cloneElement(child, stepProps);

          return (
            <>
              {element}
              {!isLast && <StepConnector isComplete={stepProps.isComplete} />}
            </>
          );
        })}
      </Stack>
    </Box>
  );
}

interface StepProps {
  children?: React.ReactNode;
  stepIndex?: number;
  isComplete?: boolean;
  isActive?: boolean;
}

function Step({ children, stepIndex = 0, isActive = false, isComplete = false }: StepProps) {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing="2">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="40px"
        height="40px"
        borderRadius="50%"
        background={isActive ? 'blue.500' : isComplete ? 'blue.500' : 'gray.300'}
        color="white"
      >
        <Text fontSize="md" fontWeight="medium">
          {stepIndex + 1}
        </Text>
      </Box>
      <Box color={isActive ? 'gray.900' : isComplete ? 'gray.900' : 'gray.500'}>
        <Text fontSize="sm" fontWeight="medium">
          {children}
        </Text>
      </Box>
    </Stack>
  );
}

function StepConnector({ isComplete = false }: { isComplete?: boolean }) {
  return (
    <Box flex="1 1 auto">
      <Box height="2px" background={isComplete ? 'blue.500' : 'gray.300'} />
    </Box>
  );
}

Stepper.Step = Step;

export default Stepper;
