import { useEffect } from 'react';
import { useAuthentication } from '~auth/useAuthentication';

export function LogoutPage() {
  const { logout } = useAuthentication();

  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  return null;
}
