import ExtendableError from 'extendable-error';
import hexToArrayBuffer from 'hex-to-array-buffer';

function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
  return btoa(
    new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''),
  );
}

export function encodeMD5Checksum(checksum: string) {
  try {
    return arrayBufferToBase64(hexToArrayBuffer(checksum));
  } catch (error) {
    throw new MD5ChecksumEncodingError('Encoding MD5 failed', error as Error);
  }
}

export class MD5ChecksumEncodingError extends ExtendableError {
  constructor(message: string, private error: Error) {
    super(message);
  }
}
