import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import IndexPage from '~pages/Index';
import { useAuthentication } from './useAuthentication';

function PrivateRoute() {
  const location = useLocation();
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated) {
    return <Navigate replace state={{ from: location }} to="/login" />;
  }

  return <IndexPage />;
}

export default PrivateRoute;
