import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import PrivateRoute from '~auth/PrivateRoute';
import { useAuthentication } from '~auth/useAuthentication';
import FirmwarePage from '~pages/Firmware';
import { LoginPage } from '~pages/Login';
import { LogoutPage } from '~pages/Logout';
import NotFoundPage from '~pages/NotFound';
import OrganizationsPage from '~pages/Organizations';

function App() {
  const { isLoading } = useAuthentication();

  return (
    <>
      {isLoading ? (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      ) : (
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="firmware" element={<FirmwarePage />} />
            <Route path="organizations" element={<OrganizationsPage />} />
            <Route path="/" element={<Navigate to="firmware" />} />
          </Route>

          <Route path={'/login'} element={<LoginPage />} />
          <Route path={'/logout'} element={<LogoutPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </>
  );
}

export default App;
