import { Box } from '@chakra-ui/react';
import React from 'react';
import { DropzoneState } from 'react-dropzone';

type Props = DropzoneState & {
  dragActiveLabel?: React.ReactNode;
  dragInactiveLabel?: React.ReactNode;
};

function Dropzone({
  getRootProps,
  getInputProps,
  isDragActive,
  dragActiveLabel,
  dragInactiveLabel,
}: Props) {
  return (
    <Box
      {...getRootProps()}
      background="gray.100"
      borderWidth="thin"
      borderColor="gray.200"
      borderRadius="md"
      height="400px"
      cursor="pointer"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <input {...getInputProps()} />
      <Box>{isDragActive ? <Box>{dragActiveLabel}</Box> : <Box>{dragInactiveLabel}</Box>}</Box>
    </Box>
  );
}

export default Dropzone;
