import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  DeleteOrganizationModal_OrganizationFragment,
  DeleteOrganizationMutation,
  useDeleteOrganizationMutation,
} from '~components/organizations/__generated__/DeleteOrganizationModal.graphql';
import { getErrorMessage } from '~graphql/error';

type Props = {
  organization: DeleteOrganizationModal_OrganizationFragment;
  isOpen: React.ComponentProps<typeof Modal>['isOpen'];
  onClose: React.ComponentProps<typeof Modal>['onClose'];
  onSuccess: (data: DeleteOrganizationMutation['organizationDelete']) => void;
};

export function DeleteOrganizationModal({ organization, isOpen, onClose, onSuccess }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <DeleteOrganizationModalContent
          organization={organization}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

function DeleteOrganizationModalContent({
  organization,
  onClose,
  onSuccess,
}: {
  organization: DeleteOrganizationModal_OrganizationFragment;
  onClose: Props['onClose'];
  onSuccess: Props['onSuccess'];
}) {
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const toast = useToast({ position: 'top' });

  const onSubmit = useCallback(async () => {
    try {
      const { data } = await deleteOrganization({
        variables: {
          input: {
            organizationId: organization.id,
          },
        },
      });

      if (!data || !data.organizationDelete) {
        throw new Error('Delete Organization failed');
      }

      onSuccess(data.organizationDelete);
    } catch (error) {
      let errorDescription = 'Unknown internal server error';
      switch (getErrorMessage(error)) {
        case 'ORGANIZATION_HAS_CUSTOMERS':
          errorDescription = 'Cannot delete an organization that has customers';
          break;
        case 'ORGANIZATION_HAS_MULTIPLE_USERS':
          errorDescription = 'Cannot delete an organization that has more than 1 user';
          break;
        case 'ORGANIZATION_HAS_NO_ADMIN':
          errorDescription = 'Cannot delete an organization that has no admin user';
          break;
        case 'ORGANIZATION_IS_CONNECTED_TO_PROSTORE':
          errorDescription = 'Cannot delete an organization that is connected to Prostore';
          break;
        default: {
          console.log('Unhandled error: ', error);
          break;
        }
      }

      toast({
        title: 'Could not delete organization',
        description: errorDescription,
        status: 'error',
      });
    }
  }, [onSuccess, organization.id, toast, deleteOrganization]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Delete organization {organization.name}</ModalHeader>
      <ModalBody>
        <VStack w="full" align="left">
          <Box>
            <Text>
              Are you sure you want to delete <Text as={'i'}>{organization.name}</Text> ?
            </Text>
            <Text color={'red.600'} marginTop="6">
              This action can not be undone.
            </Text>
          </Box>
        </VStack>
        <ModalFooter>
          <Box>
            <Button outline={'none'} variant="ghost" isDisabled={isSubmitting} onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="solid"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              colorScheme={'red'}
              border={'red'}
            >
              Delete
            </Button>
          </Box>
        </ModalFooter>
      </ModalBody>
    </form>
  );
}

DeleteOrganizationModal.graphql = {
  fragments: gql`
    fragment DeleteOrganizationModal_organization on Organization {
      id
      name
      handle
    }
  `,
  mutations: {
    DeleteOrganization: gql`
      mutation DeleteOrganization($input: OrganizationDeleteInput!) {
        organizationDelete(input: $input) {
          id
          name
        }
      }
    `,
  },
};
