import { gql } from '@apollo/client';
import { Button, useToast } from '@chakra-ui/react';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import {
  ProStoreDeveloperAccess_OrganizationFragment,
  useToggleProstoreDeveloperAccessMutation,
} from '~components/organizations/__generated__/ProStoreDeveloperAccess.graphql';
import { fromError } from '~graphql/error';

interface Props {
  organization: ProStoreDeveloperAccess_OrganizationFragment;
}

export function ProStoreDeveloperAccess({ organization }: Props) {
  const toast = useToast();

  const [toggleDeveloperAccess, { loading }] = useToggleProstoreDeveloperAccessMutation();

  const handleOnToggleDeveloperAccess = useCallback(
    async (status: boolean) => {
      try {
        await toggleDeveloperAccess({
          variables: {
            input: {
              organizationId: organization.id,
              enabled: status,
            },
          },
        });
      } catch (error) {
        const action = status ? 'enable' : 'disable';
        const title = `Could not ${action} ProStore developer access`;

        toast({
          status: 'error',
          title: title,
          description: fromError(error, 'ToggleProstoreDeveloperAccess', {
            ORGANIZATION_NOT_CONNECTED_TO_PRO_STORE:
              'Organization is not connected to the ProStore.',
          }),
        });
      }
    },
    [toggleDeveloperAccess, organization, toast],
  );

  const enableDeveloperAccess = useCallback(async () => {
    return handleOnToggleDeveloperAccess(true);
  }, [handleOnToggleDeveloperAccess]);

  const disableDeveloperAccess = useCallback(async () => {
    return handleOnToggleDeveloperAccess(false);
  }, [handleOnToggleDeveloperAccess]);

  if (isNil(organization.proStore)) {
    return <>Not connected</>;
  }

  if (organization.proStore.developerAccess) {
    return (
      <Button
        variant="outline"
        onClick={disableDeveloperAccess}
        isLoading={loading}
        isDisabled={loading}
      >
        Disable
      </Button>
    );
  }

  return (
    <Button
      variant="outline"
      onClick={enableDeveloperAccess}
      isLoading={loading}
      isDisabled={loading}
    >
      Enable
    </Button>
  );
}

ProStoreDeveloperAccess.graphql = {
  fragments: {
    ProStoreDeveloperAccess_organization: gql`
      fragment ProStoreDeveloperAccess_organization on Organization {
        id
        proStore {
          id
          createdAt
          developerAccess
        }
      }
    `,
  },
  mutations: {
    ToggleProstoreDeveloperAccess: gql`
      mutation ToggleProstoreDeveloperAccess(
        $input: OrganizationToggleProstoreDeveloperAccessInput!
      ) {
        organizationToggleProstoreDeveloperAccess(input: $input) {
          id
          ...ProStoreDeveloperAccess_organization
        }
      }
    `,
  },
};
