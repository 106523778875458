import { get } from 'lodash';

export interface GraphQLResponseWithNetworkError {
  networkError: {
    statusCode: number;
    result: {
      errors: Array<{
        message: string;
      }>;
    };
  };
}

export interface GraphQLResponseWithGraphQLError {
  graphQLErrors: Array<{
    message: string;
  }>;
}

export function hasNetworkError(err: unknown): err is GraphQLResponseWithNetworkError {
  if (!(err instanceof Error)) {
    return false;
  }

  const networkErrors = get(err, 'networkError.result.errors') as unknown;
  return Array.isArray(networkErrors) && networkErrors.length > 0;
}

export function hasGraphQLError(err: unknown): err is GraphQLResponseWithGraphQLError {
  if (!(err instanceof Error)) {
    return false;
  }

  const graphqlErrors = get(err, 'graphQLErrors') as unknown;
  return Array.isArray(graphqlErrors) && graphqlErrors.length > 0;
}

export function getErrorMessage(err: unknown): string | undefined {
  if (hasGraphQLError(err)) {
    return err.graphQLErrors[0].message as string;
  }

  if (hasNetworkError(err)) {
    return err.networkError.result.errors[0].message as string;
  }

  return undefined;
}

export function fromError(err: unknown, operation: string, errorMap: Record<string, string> = {}) {
  const defaultErrorMessage = 'Looks like something went wrong. Please try again later.';
  const errMessage = getErrorMessage(err);

  if (!errMessage) {
    console.error('An unexpected error occurred', operation, err);
    return defaultErrorMessage;
  }

  const message = errorMap[errMessage];

  if (!message) {
    console.error('An unexpected error occurred', operation, err);
    return defaultErrorMessage;
  }

  return message;
}
