import { gql } from '@apollo/client';
import { Button, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { fromError } from '~graphql/error';
import {
  ProStoreConnection_OrganizationFragment,
  useConnectToProStoreMutation,
} from './__generated__/ProStoreConnection.graphql';

interface Props {
  organization: ProStoreConnection_OrganizationFragment;
}

export function ProStoreConnection({ organization }: Props) {
  const toast = useToast();
  const [connectToProStore, { loading }] = useConnectToProStoreMutation();
  const handleOnProStoreConnect = useCallback(async () => {
    try {
      await connectToProStore({
        variables: {
          input: {
            organizationId: organization.id,
          },
        },
      });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Could not connect to ProStore',
        description: fromError(err, 'ConnectToProStore', {
          ORGANIZATION_ALREADY_CONNECTED_TO_PRO_STORE:
            'Organization is already connected to the ProStore.',
        }),
      });
    }
  }, [connectToProStore, organization, toast]);

  return (
    <>
      {organization.proStore ? null : (
        <Button
          variant="outline"
          onClick={handleOnProStoreConnect}
          isLoading={loading}
          isDisabled={loading}
        >
          Connect to ProStore
        </Button>
      )}
    </>
  );
}

ProStoreConnection.graphql = {
  fragments: {
    ProStoreConnection_organization: gql`
      fragment ProStoreConnection_organization on Organization {
        id
        proStore {
          id
          createdAt
          developerAccess
        }
      }
    `,
  },
  mutations: {
    ConnectToProStore: gql`
      mutation ConnectToProStore($input: OrganizationConnectToProStoreInput!) {
        organizationConnectToProStore(input: $input) {
          id
          ...ProStoreConnection_organization
        }
      }
    `,
  },
};
