import { Center, Stack, Text } from '@chakra-ui/react';

interface Props {
  error: unknown;
}

function Oops({ error }: Props) {
  console.log('Oops:', JSON.stringify(error));

  return (
    <Center width="100%">
      <Stack>
        <Text>It looks like something went wrong, sorry!</Text>
      </Stack>
    </Center>
  );
}

export default Oops;
