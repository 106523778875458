import axios, { AxiosError, CancelTokenSource } from 'axios';
import ExtendableError from 'extendable-error';
import { useCallback, useState } from 'react';

export function useS3Upload() {
  const [uploadError, setError] = useState<S3FileUploadError | null>(null);
  const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const upload = useCallback(async (signedUrl: string, file: File, md5Checksum: string) => {
    setError(null);
    setUploadProgress(0);
    setIsUploading(true);

    const source = axios.CancelToken.source();
    setCancelSource(source);

    await axios
      .put(signedUrl, file, {
        headers: {
          'Content-Type': 'binary/octet-stream',
          'Content-MD5': md5Checksum,
        },
        cancelToken: source.token,
        onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      })
      .catch((err: AxiosError) => {
        const uploadError = new S3FileUploadError('S3 File upload failed', err);
        setUploadProgress(0);
        setError(uploadError);
      })
      .finally(() => {
        setIsUploading(false);
        setCancelSource(null);
      });
  }, []);

  const cancelUpload = useCallback(() => {
    cancelSource?.cancel();
  }, [cancelSource]);

  return {
    upload,
    cancelUpload,
    uploadProgress,
    isUploading,
    uploadError,
  };
}

export class S3FileUploadError extends ExtendableError {
  constructor(message: string, private error: AxiosError) {
    super(message);
  }
}
