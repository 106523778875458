import {
  ApolloClient,
  ApolloProvider as RawApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useMemo } from 'react';
import { useAuthentication } from '~auth/useAuthentication';

interface Props {
  uri: string;
  children: React.ReactNode;
}

function ApolloProvider({ uri, children }: Props) {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuthentication();

  const authLink = useMemo(() => {
    return setContext(async () => {
      if (!isAuthenticated) {
        return {};
      }

      const token = await getAccessTokenSilently();

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    });
  }, [isAuthenticated, getAccessTokenSilently]);

  const errorLink = useMemo(() => {
    return onError(({ graphQLErrors }) => {
      const hasUnauthenticatedError = graphQLErrors?.some((err) => err.extensions?.code === '401');

      if (hasUnauthenticatedError) {
        logout();
      }
    });
  }, [logout]);

  const httpLink = useMemo(() => {
    return new HttpLink({
      uri,
    });
  }, [uri]);

  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(errorLink).concat(httpLink),
      connectToDevTools: process.env.NODE_ENV !== 'production',
    });
  }, [authLink, errorLink, httpLink]);

  return <RawApolloProvider client={client}>{children}</RawApolloProvider>;
}

export default ApolloProvider;
