import { ExtractFeatures, fromEnv, Keat } from 'keat';
import React, { useMemo } from 'react';
import { useAuthentication } from '~auth/useAuthentication';

declare module 'keat' {
  interface KeatNode {
    user: {
      name: string;
      roles: string[];
      permissions: string[];
    };
  }
}

export type Feature = ExtractFeatures<typeof keat>;

export const keat = Keat.create({
  audiences: {
    staff: (user) =>
      (user?.roles?.includes('Staff') || user?.permissions?.includes('feature:staff')) ?? false,
    stakeholders: (user) =>
      (user?.roles?.includes('Stakeholder') ||
        user?.permissions?.includes('feature:stakeholder')) ??
      false,
  },
  features: {
    prostore: fromEnv(process.env.REACT_APP_ENABLE_PROSTORE_TO, 'staff'),
  },
});

export function useFeatureFlag(feature: Feature) {
  const auth = useAuthentication();

  return useMemo(
    () => ({
      isEnabled: keat.isEnabled(feature, {
        name: auth.user.name,
        roles: auth.user['https://keat.io/audiences'] ?? [],
        permissions: auth.user.permissions,
      }),
    }),
    [feature, auth],
  );
}

interface Props {
  feature: Feature;
  children: React.ReactNode;
}

export function FeatureFlag({ feature, children }: Props) {
  const { isEnabled } = useFeatureFlag(feature);

  if (!isEnabled) return null;

  return <>{children}</>;
}
