/* THIS FILE HAS BEEN COPIED FROM PORTAL DO NOT EDIT IT DIRECTLY */
import { Avatar, Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React from 'react';
import { User } from '~auth/useAuthentication';

interface Props {
  user: User;
  onLogout: () => void;
}

function UserMenu({ user, onLogout }: Props) {
  return (
    <Menu>
      <MenuButton as={UserMenuButton} username={user.name} />
      <MenuList>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );
}

interface MenuButtonProps {
  username: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const UserMenuButton = React.forwardRef(function UserMenuButton(
  props: MenuButtonProps,
  ref: React.LegacyRef<HTMLDivElement>,
) {
  return (
    <Box ref={ref} onClick={props.onClick} role="button">
      <Avatar
        name={props.username}
        size="sm"
        bg="gray.50"
        color="gray.800"
        _hover={{
          backgroundColor: 'blue.200',
        }}
      />
    </Box>
  );
});

export default UserMenu;
