/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ToggleMfaStatusMutationVariables = Types.Exact<{
  input: Types.ToggleMfaInput;
}>;


export type ToggleMfaStatusMutation = { __typename?: 'Mutation', toggleMfaStatus: { __typename?: 'Organization', id: string, name: string } };


export const ToggleMfaStatusDocument = gql`
    mutation ToggleMfaStatus($input: ToggleMfaInput!) {
  toggleMfaStatus(input: $input) {
    id
    name
  }
}
    `;
export type ToggleMfaStatusMutationFn = ApolloReactCommon.MutationFunction<ToggleMfaStatusMutation, ToggleMfaStatusMutationVariables>;

/**
 * __useToggleMfaStatusMutation__
 *
 * To run a mutation, you first call `useToggleMfaStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMfaStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMfaStatusMutation, { data, loading, error }] = useToggleMfaStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleMfaStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleMfaStatusMutation, ToggleMfaStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ToggleMfaStatusMutation, ToggleMfaStatusMutationVariables>(ToggleMfaStatusDocument, options);
      }
export type ToggleMfaStatusMutationHookResult = ReturnType<typeof useToggleMfaStatusMutation>;
export type ToggleMfaStatusMutationResult = ApolloReactCommon.MutationResult<ToggleMfaStatusMutation>;
export type ToggleMfaStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleMfaStatusMutation, ToggleMfaStatusMutationVariables>;