import { AxiosError, CancelTokenSource } from 'axios';
import ExtendableError from 'extendable-error';
import { useCallback, useState } from 'react';

export function useMockS3Upload() {
  const [uploadError, setUploadError] = useState<S3FileUploadError | null>(null);
  const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const upload = useCallback(async () => {
    setUploadError(null);
    setUploadProgress(0);
    setIsUploading(true);

    await new Promise<void>((resolve, reject) => {
      let progress = 0;

      const progressInterval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        if (progress >= 100) {
          clearInterval(progressInterval);
          resolve();
        }
      }, 200);

      setTimeout(() => {
        clearInterval(progressInterval);

        const error = null;
        if (error) {
          const uploadError = new S3FileUploadError('S3 File upload failed', error);
          setUploadError(uploadError);
          setUploadProgress(0);
          reject(uploadError);
        } else {
          resolve();
        }
      }, 2000);

      setTimeout(() => {
        setIsUploading(false);
        setCancelSource(null);
      }, 2100);
    });
  }, []);

  const cancelUpload = useCallback(() => {
    cancelSource?.cancel();
  }, [cancelSource]);

  return {
    upload,
    cancelUpload,
    uploadProgress,
    isUploading,
    uploadError,
  };
}

export class S3FileUploadError extends ExtendableError {
  constructor(message: string, private error: AxiosError) {
    super(message);
  }
}
