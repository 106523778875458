/* THIS FILE HAS BEEN COPIED FROM PORTAL DO NOT EDIT IT DIRECTLY */
import { Box, Stack } from '@chakra-ui/react';
import React from 'react';

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
  children: React.ReactNode;
}

function AppBar({ left, right, children }: Props) {
  return (
    <Box bg="blue.900" boxShadow="sm">
      <Stack direction="row">
        <Box px="16" py="4">
          {left}
        </Box>
        <Stack display="flex" flex="1" justifyContent="center" alignItems="flex-end">
          {children}
        </Stack>
        <Box px="16" py="4">
          {right}
        </Box>
      </Stack>
    </Box>
  );
}

export default AppBar;
