import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import fonts from './fonts';

const theme = extendTheme({
  colors,
  fonts,
  styles: {
    global: {
      'html, body': {
        height: '100%',
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '5px',
      },
      sizes: {
        md: {
          pl: 5,
          pr: 5,
        },
      },
      variants: {
        solid: {
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: 'blue.400',
        },
      },
      defaultProps: {
        size: 'md',
        colorScheme: 'blue',
        variant: 'solid',
      },
    },
    Table: {
      parts: ['td'],
      baseStyle: {
        td: {
          fontSize: 'sm',
        },
      },
    },
  },
});

export default theme;
