/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteOrganizationModal_OrganizationFragment = { __typename?: 'Organization', id: string, name: string, handle: string };

export type DeleteOrganizationMutationVariables = Types.Exact<{
  input: Types.OrganizationDeleteInput;
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', organizationDelete: { __typename?: 'Organization', id: string, name: string } };

export const DeleteOrganizationModal_OrganizationFragmentDoc = gql`
    fragment DeleteOrganizationModal_organization on Organization {
  id
  name
  handle
}
    `;
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($input: OrganizationDeleteInput!) {
  organizationDelete(input: $input) {
    id
    name
  }
}
    `;
export type DeleteOrganizationMutationFn = ApolloReactCommon.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = ApolloReactCommon.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;