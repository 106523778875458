/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ProStoreConnection_OrganizationFragment = { __typename?: 'Organization', id: string, proStore?: { __typename?: 'ProStoreConnection', id: string, createdAt: any, developerAccess: boolean } | null };

export type ConnectToProStoreMutationVariables = Types.Exact<{
  input: Types.OrganizationConnectToProStoreInput;
}>;


export type ConnectToProStoreMutation = { __typename?: 'Mutation', organizationConnectToProStore: { __typename?: 'Organization', id: string, proStore?: { __typename?: 'ProStoreConnection', id: string, createdAt: any, developerAccess: boolean } | null } };

export const ProStoreConnection_OrganizationFragmentDoc = gql`
    fragment ProStoreConnection_organization on Organization {
  id
  proStore {
    id
    createdAt
    developerAccess
  }
}
    `;
export const ConnectToProStoreDocument = gql`
    mutation ConnectToProStore($input: OrganizationConnectToProStoreInput!) {
  organizationConnectToProStore(input: $input) {
    id
    ...ProStoreConnection_organization
  }
}
    ${ProStoreConnection_OrganizationFragmentDoc}`;
export type ConnectToProStoreMutationFn = ApolloReactCommon.MutationFunction<ConnectToProStoreMutation, ConnectToProStoreMutationVariables>;

/**
 * __useConnectToProStoreMutation__
 *
 * To run a mutation, you first call `useConnectToProStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectToProStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectToProStoreMutation, { data, loading, error }] = useConnectToProStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectToProStoreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectToProStoreMutation, ConnectToProStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ConnectToProStoreMutation, ConnectToProStoreMutationVariables>(ConnectToProStoreDocument, options);
      }
export type ConnectToProStoreMutationHookResult = ReturnType<typeof useConnectToProStoreMutation>;
export type ConnectToProStoreMutationResult = ApolloReactCommon.MutationResult<ConnectToProStoreMutation>;
export type ConnectToProStoreMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectToProStoreMutation, ConnectToProStoreMutationVariables>;