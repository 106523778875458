/* THIS FILE HAS BEEN COPIED FROM PORTAL DO NOT EDIT IT DIRECTLY */
import { Box, BoxProps, Link as ChakraLink, Stack } from '@chakra-ui/react';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

function Breadcrumb({ children }: Props) {
  return (
    <Stack direction="row" spacing="4" fontSize="sm" fontWeight="medium">
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return;
        const isLast = index === React.Children.toArray(children).length - 1;
        const props: React.HTMLAttributes<HTMLElement> = isLast
          ? { color: 'blue.500' }
          : { color: 'inherit' };

        const element = React.cloneElement(child, props);

        return (
          <>
            {element}
            {!isLast && <Divider />}
          </>
        );
      })}
    </Stack>
  );
}

const Item = React.forwardRef(function Item(props: BoxProps, ref: React.LegacyRef<HTMLDivElement>) {
  return <Box ref={ref} {...props} />;
});

const ItemLink = React.forwardRef(function Item(
  props: BoxProps & Pick<LinkProps, 'to'>,
  ref: React.LegacyRef<HTMLDivElement>,
) {
  const { children, to, ...rest } = props;

  return (
    <Box ref={ref} {...rest}>
      <ChakraLink as={Link} to={to}>
        {children}
      </ChakraLink>
    </Box>
  );
});

function Divider() {
  return <Box>{'/'}</Box>;
}

Breadcrumb.Item = Item;
Breadcrumb.ItemLink = ItemLink;

export default Breadcrumb;
